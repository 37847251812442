.edition-journalist table {
    width: 80%;
    background-color: #fafafa;
    overflow-x: scroll;
}

.journalist-container {
    margin-top: 1.5em;
    margin-bottom: 10px;
    width: 80vw;
    background-color: #fafafa;
}

.checkbox {
    width: 30px;
    height: 20px;
}

.edition-journalist .react-bs-container-body {
    overflow-x: scroll;
}

.journalist-container .table-header-wrapper {
    position: sticky;
    top: 0em;
    z-index: 1;
    background-color: #fafafa;
    text-align: start;
    overflow-x: scroll;
}

.fixed-position {
    position: fixed;
}

.journalist-container .table-header-wrapper {
    -ms-overflow-style: none;
    /* Internet Explorer 10+ */
    scrollbar-width: none;
    /* Firefox */
}

.journalist-container .table-header-wrapper::-webkit-scrollbar {
    width: 0;
    /* Remove scrollbar space */
    height: 0;
    display: none;
    background: transparent;
    /* Safari and Chrome */
}

.journalist-container td {
    padding: 14px 10px;

}

.journalist-container tr {
    padding: 14px 10px;
    border-bottom: 1px solid #9e9e9e;
}

.journalist-container table {
    border-collapse: collapse;
}

.react-bs-container-body .table-bordered {
    margin-bottom: 20px;
}

.journalist-container th[data-field='journalistName'] {
    box-shadow: 4px 0px 4px 0px rgb(202 202 202 / 75%) !important;
}
.journalist-name{
    text-overflow: ellipsis;
}

.journalist-container th[data-field='journalistName']::after {
    width: 1px;
    content: '';
    position: absolute;
    top: 0;
    background: transparent;
    height: 100%;
    right: 1px;
}

#container-body{
    width:80vw;
    position: absolute;
    top:6em;
}
.journalist-container .react-bs-container-body{
    height: 70vh !important;
}

.journalist-container colgroup col:first-of-type{
    width:15em !important ;
    min-width: 12em !important;
}
.journalist-container .react-bs-container-body colgroup col:first-of-type{
    width:12em !important ;
    min-width: 12em !important;
}