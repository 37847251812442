.coupon-row{
    display:flex !important;
    justify-content: flex-end;
}

.coupon-row .MuiTableCell-footer{
    padding: 0px !important;
    border-bottom: none !important;
}
.coupon-footer .MuiTableCell-root{
    padding: 2px !important;
}