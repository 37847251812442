  .custom-text-data{
    width:45% !important;
  }
  
  .input-fields{
    display: flex !important;
    width: 45% !important;
    margin-right: 50px !important;
  }

  .custom-text{
    width: 100% !important;
  }

  .error-div{
    color: #812c3c;
    background: #fdd7da;
    border-radius: 5px;
    display: block;
    font-size: 18px;
    padding: 18px 9px;
    margin-bottom: 20px;
  }

  .custom-btn{
    width: auto !important;
    text-decoration: underline !important;
    padding-left: 0 !important;
    color: #b3b4b4 !important;
    font-size: 16px !important;
    font-weight: 700 !important;
  }
  .enable-btn{
    width: auto !important;
    text-decoration: underline !important;
    padding-left: 0 !important;
    color: #010203 !important;
    font-size: 16px !important;
    font-weight: 700 !important;
  }
  
  .s-form > div:first-child {
    display: block !important;
  }

  .contactContainer{
    width: 100% !important;
  }