
  .logo-container {
    width: 30px;
  }

  .editions-schedule .table {
    width: 80%;
  }

  .edition-container {
    display: flex;
  }

  .img-container {
    display: flex;
  }

  .version-container {
    display: flex;
    align-items: center;
  }

  .checkbox-field {
    width: 30px;
    height: 20px;
  }
  .scroll-class{
    margin-top:2em;
  }
  
  .scroll-class table{
    border-collapse: collapse;
}
  .scroll-class .table-header-wrapper {
    position: sticky;
    top: 0;
    opacity: 1;
    height: 45px;
    background-color: #fafafa;
  }

  .scroll-class tr {
    border-bottom: 1px solid #9e9e9e !important;
  }

  .scroll-class th {
    padding-bottom: 1em !important;
    border-bottom: 1px solid #9e9e9e !important;
  }
