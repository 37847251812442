body {
 margin: 0;
 padding: 0;
 font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
 -webkit-font-smoothing: antialiased;
 -moz-osx-font-smoothing: grayscale;
}

code {
 font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
 -webkit-appearance: none;
 margin: 0;
}

/* Firefox */
input[type='number'] {
 -moz-appearance: textfield;
}

.simple-form  div {
  display: flex;
  flex-wrap: wrap;
}
.mycheckbox {
    max-width: 230px;
    left: 10px;
  }
.coupon-row{
    display:flex !important;
    justify-content: flex-end;
}

.coupon-row .MuiTableCell-footer{
    padding: 0px !important;
    border-bottom: none !important;
}
.coupon-footer .MuiTableCell-root{
    padding: 2px !important;
}
  .custom-text-data{
    width:45% !important;
  }
  
  .input-fields{
    display: flex !important;
    width: 45% !important;
    margin-right: 50px !important;
  }

  .custom-text{
    width: 100% !important;
  }

  .error-div{
    color: #812c3c;
    background: #fdd7da;
    border-radius: 5px;
    display: block;
    font-size: 18px;
    padding: 18px 9px;
    margin-bottom: 20px;
  }

  .custom-btn{
    width: auto !important;
    text-decoration: underline !important;
    padding-left: 0 !important;
    color: #b3b4b4 !important;
    font-size: 16px !important;
    font-weight: 700 !important;
  }
  .enable-btn{
    width: auto !important;
    text-decoration: underline !important;
    padding-left: 0 !important;
    color: #010203 !important;
    font-size: 16px !important;
    font-weight: 700 !important;
  }
  
  .s-form > div:first-child {
    display: block !important;
  }

  .contactContainer{
    width: 100% !important;
  }

  .logo-container {
    width: 30px;
  }

  .editions-schedule .table {
    width: 80%;
  }

  .edition-container {
    display: flex;
  }

  .img-container {
    display: flex;
  }

  .version-container {
    display: flex;
    align-items: center;
  }

  .checkbox-field {
    width: 30px;
    height: 20px;
  }
  .scroll-class{
    margin-top:2em;
  }
  
  .scroll-class table{
    border-collapse: collapse;
}
  .scroll-class .table-header-wrapper {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    opacity: 1;
    height: 45px;
    background-color: #fafafa;
  }

  .scroll-class tr {
    border-bottom: 1px solid #9e9e9e !important;
  }

  .scroll-class th {
    padding-bottom: 1em !important;
    border-bottom: 1px solid #9e9e9e !important;
  }

.edition-journalist table {
    width: 80%;
    background-color: #fafafa;
    overflow-x: scroll;
}

.journalist-container {
    margin-top: 1.5em;
    margin-bottom: 10px;
    width: 80vw;
    background-color: #fafafa;
}

.checkbox {
    width: 30px;
    height: 20px;
}

.edition-journalist .react-bs-container-body {
    overflow-x: scroll;
}

.journalist-container .table-header-wrapper {
    position: -webkit-sticky;
    position: sticky;
    top: 0em;
    z-index: 1;
    background-color: #fafafa;
    text-align: start;
    overflow-x: scroll;
}

.fixed-position {
    position: fixed;
}

.journalist-container .table-header-wrapper {
    -ms-overflow-style: none;
    /* Internet Explorer 10+ */
    scrollbar-width: none;
    /* Firefox */
}

.journalist-container .table-header-wrapper::-webkit-scrollbar {
    width: 0;
    /* Remove scrollbar space */
    height: 0;
    display: none;
    background: transparent;
    /* Safari and Chrome */
}

.journalist-container td {
    padding: 14px 10px;

}

.journalist-container tr {
    padding: 14px 10px;
    border-bottom: 1px solid #9e9e9e;
}

.journalist-container table {
    border-collapse: collapse;
}

.react-bs-container-body .table-bordered {
    margin-bottom: 20px;
}

.journalist-container th[data-field='journalistName'] {
    box-shadow: 4px 0px 4px 0px rgb(202 202 202 / 75%) !important;
}
.journalist-name{
    text-overflow: ellipsis;
}

.journalist-container th[data-field='journalistName']::after {
    width: 1px;
    content: '';
    position: absolute;
    top: 0;
    background: transparent;
    height: 100%;
    right: 1px;
}

#container-body{
    width:80vw;
    position: absolute;
    top:6em;
}
.journalist-container .react-bs-container-body{
    height: 70vh !important;
}

.journalist-container colgroup col:first-of-type{
    width:15em !important ;
    min-width: 12em !important;
}
.journalist-container .react-bs-container-body colgroup col:first-of-type{
    width:12em !important ;
    min-width: 12em !important;
}
.tabs-container{
    width:80vw;
    overflow-x: hidden;
} 
